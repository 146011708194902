import { useContext, createContext, useState, useEffect } from "react";

//google auth
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect, signOut, onAuthStateChanged, getAdditionalUserInfo } from "firebase/auth";

//firebase
import { db } from '../firebase'
import { doc, onSnapshot, collection, setDoc, addDoc, getDocs, query, deleteDoc } from 'firebase/firestore';

import { auth } from "../firebase";

const AuthContext = createContext()

export const AuthContextProvider = ({children}) => {

    const [user, setUser] = useState()
    const [admin, setAdmin] = useState(false)
    const [isNew, setIsNew] = useState(false)

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider).then((result => {
            setIsNew(getAdditionalUserInfo(result).isNewUser)
        }))
        
    }

    const logOut = () => {
        signOut(auth)
    }

    useEffect(() => {
        const unsubscribe =  onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            if(isNew){
                setDoc(doc(db, 'users', currentUser.uid), {
                    mail: currentUser.email,
                    dateChange: false
                }, {merge: true})
            }
            if(currentUser.uid === "RkdtpMRvbch3Rrr27rqt9SroLzE2" || currentUser.uid === "1E8cH4iWeLg7j0fzPMaNwAOGnJ82"){
                setAdmin(true)
            } else {
                setAdmin(false)
            }
        })
        return () => {
            unsubscribe()
        }
    }, [])
    

    return (
        <AuthContext.Provider value={{ googleSignIn, logOut, user, admin }}>
            {children}
        </AuthContext.Provider>
    )
} 

export const UserAuth = () => {
    return useContext(AuthContext)
}