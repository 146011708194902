import React from 'react'

//router 
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import Admin from './components/Admin'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import Protected from './components/Protected'
import { AuthContextProvider } from './context/AuthContext'

function App() {
  return (
    <AuthContextProvider>
        <div style={{margin: "20px"}}>
            Neue Zeiterfassung unter: <a href="https://zeiterfassung.we-site.de/">https://zeiterfassung.we-site.de</a>
            {/* <Router>
                <Routes>
                    <Route path='/' element={<Protected><Dashboard /></Protected>}/>
                    <Route path='/login' element={<Login />}/>
                </Routes>
            </Router> */}
        </div>
    </AuthContextProvider>
  )
}

export default App