// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from 'firebase/auth'
 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyADohuLJwmZEUSC_rPno7Uc24l0eskPSgU",
    authDomain: "we-timetracking.firebaseapp.com",
    projectId: "we-timetracking",
    storageBucket: "we-timetracking.appspot.com",
    messagingSenderId: "590370084111",
    appId: "1:590370084111:web:0dd1ec341e009de857da7a"
};
  

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)
export const auth = getAuth(app)